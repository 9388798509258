import { z } from "zod"

export const PasswordSchema = z
  .string()
  .min(8, { message: "password_over_8_characters" })
  .max(255, { message: "password_under_255_characters" })
  .superRefine((value, ctx) => {
    // Salasanan tulee olla ainakin 8 merkkiä pitkä ja toteuttaa vähintään 2 seuraavista ehdoista:
    // sisältää pienen kirjaimen väliltä a-z
    // sisältää ison kirjaimen väliltä A-Z
    // sisältää jonkin merkeistä !#%/+?@$-
    // sisältää numeron

    const hasLowerCase = /[a-z]/.test(value)
    const hasUpperCase = /[A-Z]/.test(value)
    const hasSpecialChar = /[!#%/+?@$-]/.test(value)
    const hasNumber = /[0-9]/.test(value)

    // Count how many conditions are met
    const conditionsMet = [
      hasLowerCase,
      hasUpperCase,
      hasSpecialChar,
      hasNumber
    ].filter(Boolean).length

    // Password must meet at least 2 conditions
    if (conditionsMet < 2) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "password_must_meet_two_conditions"
      })
      return false
    }

    return true
  })

export const PasswordVerificationSchema = z
  .object({
    password: PasswordSchema,
    passwordConfirmation: z.string().nullable()
  })
  .superRefine((value, ctx) => {
    if (value.password !== value.passwordConfirmation) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "passwords_do_not_match"
      })
    }
  })

export const ResetPasswordSchema = z.object({
  token: z.string(),
  password: z.string()
})

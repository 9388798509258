import React, { useState } from "react"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useStore } from "../../../Models/RootStore"
import makeStyles from "@mui/styles/makeStyles"
import { Input, Button, Checkbox, Title } from "../../../Components"
import { Colors } from "../../../Utils/theme"
import Logo from "../../../Assets/Images/logo.svg"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "40rem"
  },
  logo: {
    width: "12.5rem",
    marginBottom: "1rem"
  },
  description: {
    fontSize: "1.25rem",
    color: Colors.text,
    marginBottom: "2rem"
  },
  button: {
    minHeight: "3.125rem",
    marginTop: "0.5rem",
    marginBottom: "1.75rem"
  },
  configRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: ".25rem 0 1.25rem"
  },
  link: {
    color: Colors.navy,
    textDecoration: "underline",
    backgroundColor: Colors.lightGreen,
    padding: ".3125rem .5rem",
    borderRadius: ".3125rem"
  }
}))

const Login = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sessionStore }: any = useStore()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [displayPassword, setDisplayPassword] = useState(false)
  const [rememberMe, setRememberMe] = useState(true)
  const [clicked, setClicked] = useState(false)

  const toggleDisplayPassword = () => setDisplayPassword(!displayPassword)
  const toggleRememberMe = () => setRememberMe(!rememberMe)

  const clearEmail = () => setEmail("")

  const handleKeyPress = (evt: any) => {
    if (evt.key === "Enter" && email && password) {
      login()
    }
  }
  const login = () => {
    sessionStore.login(email, password)
    // Clear password
    setPassword("")
  }

  return (
    <div className={classes.root}>
      <img
        src={Logo}
        className={classes.logo}
        onClick={() => setClicked(true)}
      />
      {clicked ? (
        <>
          <p className={classes.description}>{t("login_description")}</p>
          <Input
            placeholder={t("email")}
            value={email}
            onChange={setEmail}
            onClear={clearEmail}
            tall
            autoFocus
          />
          <Input
            type={displayPassword ? "text" : "password"}
            placeholder={t("password")}
            value={password}
            onChange={setPassword}
            onEye={toggleDisplayPassword}
            handleKeyPress={handleKeyPress}
            tall
          />

          <div className={classes.configRow}>
            <Checkbox
              label={t("remember_me")}
              checked={rememberMe}
              onChange={toggleRememberMe}
            />
            <Link to="/reset-password" className={classes.link}>
              {t("forgot_password")}
            </Link>
          </div>

          <Button
            text={t("login")}
            onClick={login}
            buttonStyle={classes.button}
            fullWidth
            tall
          />
        </>
      ) : (
        <Title
          // title={t("password_reset_done_title")}
          description={t("password_reset_done_description")}
          type="main"
          descriptionStyle={classes.description}
        />
      )}
    </div>
  )
}

export default observer(Login)

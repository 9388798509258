import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import TextField from "@mui/material/TextField"
import { Colors } from "../../Utils/theme"
import ClearIcon from "../../Assets/Icons/clear.svg"
import EyeIcon from "../../Assets/Icons/eye.svg"
import EyeOffIcon from "../../Assets/Icons/eye-hidden.svg"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: "1.5rem",
    position: "relative"
  },
  containerNoMargin: {
    marginBottom: 0,
    paddingBottom: 0
  },
  containerQuantity: {
    minWidth: "8.25rem",
    width: "8.25rem"
  },
  inputRoot: {
    width: "100%",
    borderRadius: ".625rem",
    padding: 0
  },
  inputOuterContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "row"
  },
  input: {
    flex: 1,
    width: "100%",
    height: "3.125rem",
    fontFamily: "Ambit",
    fontWeight: 400,
    fontSize: "1rem",
    color: Colors.inputTextDark,
    backgroundColor: Colors.white,
    borderRadius: ".625rem",
    padding: "0 1rem",
    "&:focus": {
      backgroundColor: Colors.white
    }
  },
  tallInput: {
    fontSize: "1.25rem",
    height: "4rem"
  },
  inputWithLeftIcon: {
    padding: "0 1rem 0 3rem"
  },
  inputWithRightIcon: {
    padding: "0 3rem 0 1rem"
  },
  multiline: {
    // minHeight: '8rem',
    // maxHeight: '8rem',
    padding: "1rem !important"
  },
  label: {
    fontSize: "1rem",
    fontWeight: 400,
    margin: 0,
    marginBottom: "0.5rem",
    color: Colors.label
  },
  iconLeft: {
    position: "absolute",
    top: ".75rem",
    left: "1rem",
    height: "1.5rem",
    userSelect: "none"
  },
  quantityContainer: {
    userSelect: "none",
    pointerEvents: "none",
    position: "absolute",
    right: 2,
    top: 2,
    bottom: 2,
    backgroundColor: Colors.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(3.125rem - 4px)",
    padding: "0 1rem",
    borderLeft: `1px solid ${Colors.border}`,
    borderTopRightRadius: "0.625rem",
    borderBottomRightRadius: "0.625rem"
  },
  quantity: {
    fontFamily: "Ambit",
    fontWeight: 400,
    fontSize: "1rem",
    color: Colors.navy
  },
  endAdornmentIconContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: Colors.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "3.125rem",
    borderLeft: `1px solid ${Colors.border}`,
    borderTopRightRadius: "0.625rem",
    borderBottomRightRadius: "0.625rem",
    userSelect: "none",
    pointerEvents: "none"
  },
  endAdornmentIcon: {
    maxWidth: "1.25rem"
    // marginRight: '.25rem'
  },
  notchedOutline: {
    border: `1px solid ${Colors.border}`
  },
  clearContainer: {
    cursor: "pointer",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    right: 0,
    bottom: 0,
    top: 0,
    width: "3.5rem",
    "&:active": {
      opacity: 0.7
    }
  },
  clearIcon: {
    width: "1.5rem"
  }
}))

export default function Input(props) {
  const { iconLeft, ...rest } = props

  const classes = useStyles()

  const getContainerClass = () => {
    const classNames = [classes.container]
    if (props.noMargin) classNames.push(classes.containerNoMargin)
    if (props.type === "quantity") classNames.push(classes.containerQuantity)
    return classNames.join(" ")
  }

  const getInputClass = () => {
    const classNames = [classes.input]
    if (props.tall) classNames.push(classes.tallInput)
    if (props.multiline) classNames.push(classes.multiline)
    if (props.iconLeft) classNames.push(classes.inputWithLeftIcon)
    if (props.onClear || props.onEye)
      classNames.push(classes.inputWithRightIcon)
    return classNames.join(" ")
  }

  const onChange = (evt) => props.onChange(evt.target.value)

  const renderQuantity = () => {
    if (props.type === "quantity") {
      return (
        <div className={classes.quantityContainer}>
          <div className={classes.quantity}>{props.unit}</div>
        </div>
      )
    }
    return null
  }

  const renderIconLeft = () => {
    if (props.iconLeft) {
      return <img src={props.iconLeft} className={classes.iconLeft} />
    }
    return null
  }

  const renderLabel = () => {
    if (props?.label) {
      return <p className={classes.label}>{props.label}</p>
    }
    return null
  }

  const renderRightAction = () => {
    if (props.onClear) {
      return (
        <div className={classes.clearContainer} onClick={props.onClear}>
          <img src={ClearIcon} className={classes.clearIcon} />
        </div>
      )
    }
    if (props.onEye) {
      return (
        <div className={classes.clearContainer} onClick={props.onEye}>
          {props.type === "password" ? (
            <img src={EyeIcon} className={classes.clearIcon} />
          ) : (
            <img src={EyeOffIcon} className={classes.clearIcon} />
          )}
        </div>
      )
    }
    return null
  }

  return (
    <div className={getContainerClass()}>
      {renderLabel()}
      <div className={classes.inputOuterContainer}>
        <TextField
          {...rest}
          id={props.id}
          value={props.value || ""}
          onKeyPress={props.handleKeyPress}
          classes={{
            root: classes.inputRoot
          }}
          InputProps={{
            classes: {
              input: getInputClass(),
              root: classes.inputRoot,
              notchedOutline: classes.notchedOutline
            }
            //endAdornment: null
          }}
          inputProps={{ maxLength: props.maxLength }}
          type={props.type}
          multiline={props.multiline}
          autoCapitalize={props.autoCapitalize}
          variant="outlined"
          autoFocus={!!props.autoFocus}
          disabled={props.disabled}
          error={props.error}
          helperText={props.helperText}
          onChange={onChange}
          label={undefined}
          InputLabelProps={
            !props.label
              ? undefined
              : {
                  shrink: false
                }
          }
          sx={{
            '& input[type="time"]::-webkit-calendar-picker-indicator': {
              filter:
                "invert(19%) sepia(62%) saturate(2227%) hue-rotate(274deg) brightness(90%) contrast(91%)"
            }
          }}
        />
        {renderQuantity()}
        {renderIconLeft()}
        {renderRightAction()}
      </div>
    </div>
  )
}

import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import { useStore } from "../../../Models/RootStore"
import makeStyles from "@mui/styles/makeStyles"
import { Title, Button, Input } from "../../../Components"
import { PasswordVerificationSchema } from "../../../Schemas/AuthSchema"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "44rem",
    [theme.breakpoints.down("xl")]: {
      maxWidth: "38rem"
    }
  },
  description: {
    marginBottom: "2rem"
  },
  button: {
    minHeight: "3.125rem",
    marginTop: "0.5rem",
    marginBottom: "1.75rem"
  }
}))

const ResetCustomerPassword = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sessionStore }: any = useStore()
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const [valid, setValid] = useState(false)
  const [error, setError] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [passwordReset, setPasswordReset] = useState(false)

  let token: string | null = null
  let email: string | null = null

  if (window.location.search) {
    const urlParams = new URLSearchParams(window.location.search)
    token = urlParams.get("token")
    email = urlParams.get("email")
  }

  useEffect(() => {
    const parser = PasswordVerificationSchema.safeParse({
      password,
      passwordConfirmation
    })
    if (parser.success) {
      setValid(true)
    } else {
      const message = parser.error.errors[0].message
      if (message.includes("_")) {
        setError(t(message))
      }
      setValid(false)
    }
  }, [password, passwordConfirmation])

  const handleSubmit = async () => {
    if (await sessionStore.resetCustomerPassword(token, password)) {
      setPasswordReset(true)
    }
  }

  if (token === "") {
    return <div>Token parameters are missing</div>
  }

  if (passwordReset) {
    return (
      <div className={classes.root}>
        <Title
          title={t("password_reset_done_title")}
          description={t("password_reset_done_description")}
          type="main"
          descriptionStyle={classes.description}
        />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <Title
        title={t("reset_password_title")}
        description={t("reset_password_description")}
        type="main"
        descriptionStyle={classes.description}
      />
      <Input
        value={password}
        onChange={(value) => setPassword(value)}
        label={t("password")}
        type={showPassword ? "input" : "password"}
        onEye={() => setShowPassword(!showPassword)}
        autoCapitalize="none"
      />
      <Input
        value={passwordConfirmation}
        onChange={(value) => setPasswordConfirmation(value)}
        label={t("password_confirmation")}
        type={showPassword ? "input" : "password"}
        onEye={() => setShowPassword(!showPassword)}
        autoCapitalize="none"
      />
      {!valid && (
        <Title
          description={error}
          type="main"
          descriptionStyle={classes.description}
        />
      )}
      <Button
        disabled={!valid}
        text={t("reset_password")}
        onClick={handleSubmit}
        buttonStyle={classes.button}
        fullWidth
      />
    </div>
  )
}

export default observer(ResetCustomerPassword)
